import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import { Icon, Text } from "@atoms";
import { m } from "framer-motion";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { navigate } from "gatsby";

const Frame = ({ title, index, siblings, next, previous, sections }) => {
  const goBack = async () => {
    try {
      await navigate(-1);
    } catch (e) {
      navigate("/");
    }
  };

  const lang = useLang();

  return (
    <>
      <div className="absolute -left-14 top-14 bottom-0 z-[300] w-14 border-r-3 border-white transition duration-300 md:left-0">
        {/* close button + section nav */}
        <div className="sticky top-0 left-0">
          <AppLink
            className="inline-block p-4"
            ariaLabel="Go back"
            onClick={() => goBack()}
          >
            <Icon name="close" className="w-5 text-white" />
          </AppLink>

          {title && (
            <div className="mx-4 mx-4 mt-12 flex items-center justify-center border-b-2 border-white pb-4 lg:mt-24 xl:mt-36">
              <Text
                variant="sidewaysLabel"
                className="pointer-events-none text-white"
              >
                {title}
              </Text>
            </div>
          )}

          <div className="mt-4 flex flex-col items-center justify-center gap-1.5">
            <AppLink
              to="/can-you-dream-it"
              className="h-2 w-2 rounded-full border-2 border-white"
              activeClassName="bg-white"
              arialLabel={t("Go to the Can You Dream It? page")}
            />
            <AppLink
              to="/can-you-build-it"
              className="h-2 w-2 rounded-full border-2 border-white"
              activeClassName="bg-white"
              arialLabel={t("Go to the Can You Build It? page")}
            />
            <AppLink
              to="/can-you-move-it"
              className="h-2 w-2 rounded-full border-2 border-white"
              activeClassName="bg-white"
              arialLabel={t("Go to the Can You Move It? page")}
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 z-[200] z-0 w-full md:left-14 md:left-14 md:w-[calc(100%-3.5rem)]">
        <m.div
          className="border-t-3 border-white"
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: "circOut", delay: 0.5 }}
        />
        <div className="flex flex-nowrap justify-between whitespace-nowrap text-white">
          <AppLink
            className={classNames("flex items-center gap-3 py-7 px-6 md:w-48", {
              "pointer-events-none opacity-0": !previous,
            })}
            ariaLabel={t("Go to the previous chapter")}
            to={previous}
          >
            <Icon name="backwardsArrow" className="w-4 shrink-0" />
            <Text variant="button" className="hidden md:block">
              {t("Previous Chapter", lang)}
            </Text>
          </AppLink>
          <AppLink
            className="mx-auto inline-flex p-4"
            ariaLabel="Return home"
            onClick={() => goBack()}
          >
            <Icon name="close" className="w-5 text-white" />
          </AppLink>
          <AppLink
            className={classNames(
              "flex items-center gap-3 whitespace-nowrap py-7 px-6 md:w-48",
              {
                "pointer-events-none opacity-0": !next,
              }
            )}
            ariaLabel={t("Go to the next chapter")}
            to={next}
          >
            <Text variant="button" className="hidden md:block">
              {t("Next Chapter", lang)}
            </Text>
            <Icon name="forwardArrow" className="w-4 shrink-0" />
          </AppLink>
        </div>
      </div>
    </>
  );
};

Frame.defaultProps = {};

export default Frame;
